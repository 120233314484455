
.scroll-x { overflow-x: auto; }
.scroll-y { overflow-y: auto; }

.myDashboardContent01{
    background: url("../images/landing-page/picImage.jpeg")  #CCC no-repeat left top;
    width: 100%;
    height: 100%;
    /* background-size: cover ;*/
    background-size: 100% 100% ;

    font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;

    flex: 0 1 auto;
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
}

div.myDashboardContent01
{
   min-height: calc(100vh - 130px);
    padding: 0 !important;
    height: auto;
    top: -20px;
    right: -15px;
    height: auto;
}

.myDashboardContent02{
    opacity: 0.8;
    background:  #D8D8D8;
    position: relative;
    min-height: calc(100vh - 130px);
    padding-top: 20px;
}
 .bockContent{
    background: #13B5EA;
    color: white;
     width: 100%;
}
.pTitleHeader{
    font-weight: bold;
    font-size: 16px;
    width: 70%;
    position: relative;
    float: left;
    padding-left: 10px;
    background: #13B5EA;
    height: 100px;

    padding-top: 15px;
    vertical-align: middle;
    /* Admin2018! */
}
.pTitleNumber{
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 25px;
    width: 25%;
    position: relative;
    background: #13B5EA;
    height: 75px;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;

}
.myColorB{
    background: #13B5EA;
    border-radius: 15px;
}
div.myColorB{
    border-left: 5px solid #D8D8D8;
}
.circle {

    -webkit-border-radius: 25px;
    -moz-border-radius: 75px;
    border-radius: 75px;
    -moz-border-right-colors: white;
    background: #13B5EA;
    border: 5px solid white;

}
.pContentBox{
    background: #4f565d;
}
.pContentBox i{
    padding-top: 10px;
    color: white;
    font-size: 54px;
}
div.makeBorderShort{
    border-left: 1px solid white;
}
div.pBorderConter1{
    height: 65px;
    background: #13B5EA;
    left:5px;
    border-radius: 5px 5px;
}
.blockContentColoum2{
    background: #4f565d;
    color: white;
    margin-left: 5px;
}
div.blockContentColoum2{
    border-bottom: 10px solid white;
}
.blockContentColoum2 p{
    width: 110%;
    let:-10px;
}
.alignMeLeft{
    text-align: left;
    font-weight: bold;
}
.alignMeRight{
    text-align: right;
    font-weight: bold;
}
.bccHeader{
    background: #13B5EA;
    /*background: orangered;*/
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}
.bccGlobal{
    background: #13B5EA;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}
.badgeColorRed{
    color:white;
    background: red;
}
.badgeColorGreen{
    color:white;
    background: green;
}
.badgeColorYellow{
    color:black;
    background: yellow;
}
.colBlock{
    margin-left: 10px;
    width: 25%;
}
.makeBock25Percent{
    width: 25%;
}

.bccChartHearder{
    color: white;
    border-radius: 15px;
    border-left: 20px solid #D8D8D8;
    border-right: 20px solid #D8D8D8;
}
.bccChartContent{
    background: white;
    border-left: 20px solid #D8D8D8;
    border-right: 20px solid #D8D8D8;
    text-align: center;
    padding-top: 50px;
    min-height: 300px;

}
.rowGlobal{
    border-radius: 55px;
}
.glbRed{
    color: red;
    font-weight: bold;
    font-size: 24px;
}
.glbGreen{
    color: green;
    font-weight: bold;
    font-size: 24px;
}
.glbYellow{
    color: yellow;
    font-weight: bold;
    font-size: 24px;
}

.myCombo2{
    -webkit-appearance: menulist-button;
    height: 35px;
    width: 85%;
}
.colorMeBrwon{
    color: orangered;
    font-size: 24px;
}