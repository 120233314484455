.footer {
    background-color: #f9fbfb;
    border-top: 1px solid #e8ebee;
    color: #7b8188;
    padding: 10px 0 40px;
    width: 100%;
    text-align: center;
    bottom: 20px;
}
.x-footer {
    background-color: #f9fbfb;
    border-top: 1px solid #e8ebee;
    color: #7b8188;
    padding: 20px 0 40px;
    width: 100%;
    bottom: 20px;
    position: relative;
}



.x-page.marketing-advert .inner {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px 5px 5px 5px;
    position: relative;
    width: 770px;
}
.x-header .inner {
    height: 68px;
    padding-top: 0;
}
.x-page .inner {
    overflow: hidden;
    *zoom: 1;
    margin: 0 auto;
    width: 960px;
}
.x-header div {
    width: 920px;
    height: 62px;
    margin: 0 auto;
    padding-top: 10px;
    position: relative;
    z-index: 3;
}

.x-page {
    font-size: 12px;
}

.x-page.marketing-advert .x-header .logo {
    height: 45px;
    width: 45px;
}
.x-header .logo {
    background: url(http://biadebt.easipath.com:33332/download/rev/file/225d84f3d1/logo-white.jpeg) no-repeat;
    display: block;
    height: 45px;
    width: 45px;
}

.card {
    margin-bottom: 0rem;
}
.pushTop{
    position: relative;
    top: 1px;
    bottom: 100px;
}

.d-100vh-va-middle {
    display: table-cell;
    height: 75vh;
    vertical-align: middle;
    position: relative;
    top: 12%;

}
