.myTableEmailValidation{
    width: 60%;
    margin: 0 auto 0;
    min-height: 50%;
    margin-bottom: 10%;
}
.myHeader{
    width: 60%;
    height: 110px;
    text-align: center;
    padding-top: 5px;
    margin: 0 auto 0;
}
.myFooter{
    width: 40%;
    height: 110px;
    text-align: center;
    padding-top: 5px;
    margin: 0 auto 0;
    margin-top: 100px;
}

.myHeader img{
    left: 50%;
}

.myContent1{
    width: 60%;
    height: 110px;
    text-align: center;
    padding-top: 5px;
    margin: 0 auto 0;
}
.myContent1 a{
    top: 20px;
    height: 50px;
    background:  #13B5EA;
    color: white;
    font-size: 16px;
    padding: 5px;
    font-weight: bold;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}
.footer {
    background-color: #f9fbfb;
    border-top: 1px solid #e8ebee;
    color: #7b8188;
    padding: 10px 0 40px;
    width: 100%;
    text-align: center;
    bottom: 20px;
}
.x-footer {
    background-color: #f9fbfb;
    border-top: 1px solid #e8ebee;
    color: #7b8188;
    padding: 20px 0 40px;
    width: 100%;
    bottom: 20px;
    position: relative;
}



.x-page.marketing-advert .inner {
    box-sizing: border-box;
    border-radius: 5px 5px 5px 5px;
    position: relative;
    width: 770px;
}
.x-header .inner {
    height: 68px;
    padding-top: 0;
}
.x-page .inner {
    overflow: hidden;
    *zoom: 1;
    margin: 0 auto;
    width: 960px;
}
.x-header div {
    width: 920px;
    height: 62px;
    margin: 0 auto;
    padding-top: 10px;
    position: relative;
    z-index: 3;
}

.x-page {
    font-size: 12px;
}

.x-page.marketing-advert .x-header .logo {
    height: 45px;
    width: 45px;
}
.x-header .logo {
    background: url(http://biadebt.easipath.com:33332/download/rev/file/225d84f3d1/logo-white.jpeg) no-repeat;
    display: block;
    height: 45px;
    width: 45px;
}

.card {
    margin-bottom: 0rem;
}
.pushTop{
    position: relative;
    top: 1px;
    bottom: 100px;
}

.d-100vh-va-middle {
    display: table-cell;
    height: 75vh;
    vertical-align: middle;
    position: relative;
    top: 12%;

}


/*@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css");*/

body{
    background-color: white;
}


.mysetting01  > .active > a, .mysetting01  > .active > a:hover, .mysetting01  > .active > a:focus {
    color: #13B5EA;
    background-color: white;
}

li.active > a{
    color: #13B5EA;
    background-color: red;
}

.nnavmenu{
    bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
}

.myPageTitle{
   /* background-color: #D8D8D8;*/
    background-color: #D8D8D8;
    top: 0;
    position: relative;
    min-height: 25px;
    padding-left:6%;
    padding-bottom: 1px;
    padding-top: 1px;
    width: 100%;
    margin-top: 0px;
    top:-20px;

}

.topMenuLevel1{
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-right: 5px;
    background: #32465A;
    color: white;
    min-height: 50px;
    position: relative;
    margin-bottom: 0px;

}
.topMenuLevel1Login{
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 5px;
    background: #13B5EA;
    color: white;
    min-height: 50px;
    text-align: center;
    margin-bottom: 0px;
}

.topMenuLevel2{
    padding-left: 50px;
    padding-top: 8px;
    padding-bottom: 0px;
    padding-right: 5px;
    background: #13B5EA;
    color: white;
}
.popupMenuLoginUser
{

    display:none;
    position: absolute;
    width: 200px;
    min-width: 200px;
    max-width: 300px;
    background: #FFF;
    border: #D7D7D7 1px solid;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10000;
}
.pmlu_01{
    top: 239px;
    left: 874px;
    display: block;
}

.dropdown-btn {
    padding: 6px 6px 6px 6px;
    text-decoration: none;
    font-size: 14px;
    color: orangered;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

.fa-caret-down {
    float: right;
    padding-right: 8px;
    font-size: 24px;
}
.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}


.dropMenu a, .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

/* On mouse-over */
.dropMenu a:hover, .dropdown-btn:hover {
    color: #f1f1f1;
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
    .dropMenu {padding-top: 15px;}
    .dropMenu a {font-size: 18px;}
}

.decoreTxt{
    color:  white;
    font-size: 14px;
    font-weight: bold;
}

.boldMe{
    font-weight: bold;
}

hr{
    color: orangered;
    border-top: 1px solid #8c8b8b;
}

hr.style18 {
    height: 5px;
    border-style: solid;
    border-color: #818a91;
    border-width: 1px 0 0 0;
    border-radius: 20px;
}
hr.style18:before {
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: #818a91;
    border-width: 0 0 1px 0;
    border-radius: 20px;
}

/*
customize the background color of menu
 */

/* navbar */
.navbar-default {
    background-color:  #13B5EA;
    border-color: #13B5EA;
    margin-top: 2px;
    padding: 0px;
}

/* Link */
.navbar-default .navbar-nav > li > a {
    color: white;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #333;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #13B5EA;
    background-color: white;

}



/* circle draw */

.circleTextFormat{
    vertical-align: middle;
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
}

.circle2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 18px;
    color:  #13B5EA;
    line-height: 50px;
    text-align: center;
    background: white;
}
.undecoraMe{
    list-style: none;
    color: white;
}

.dropdown-toggle::after {
    color: white;
}

.select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    display: block;
    width: 100%;
    height: 34px;
}

div.contentBody{
    height: 70%;
    margin-top: 20%;
    margin-bottom: 5%;
    position: absolute;
    width: 80%;
}
div.myContentAddEmployee{
    padding: 10px;
    height: 90%;
    overflow: scroll;
    margin-bottom: 5%;
}

.myCsvImport{
    margin: 0 auto 0;
    width: 40%;
}
ol.progtrckr {
    list-style-type: none;
    padding: 0;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
}

ol.progtrckr li span {
    padding: 0 1.5rem;
}

@media (max-width: 650px) {
    .progtrckr li span {
        display: none;
    }
}
.progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
}

@media (max-width: 650px) {
    .progtrckr em {
        display: inline;
    }
}

ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #CCCCCC;
}

ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
    content: "\00a0\00a0";
}

ol.progtrckr li:before {
    position: relative;
    bottom: -3.7rem;
    float: left;
    left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #CCCCCC;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #5cb85c;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
}

.myPage{
    width: 100%;
    height: 100%;
}
.myTop{
    background-color: #32465A;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    min-height: 40px;
    vertical-align: middle;
}
.myTop button{
    height: 30px;
}
.btnRegister{
    margin-left: 5px;
}
.bigImageDiv1{
    background: url(/static/media/HomePagePic.aef8928c.jpg);
    min-height: 275px;
    min-width: 100%;
    /*background-size: 100% 275px;*/

}
.bigImageDiv{
    background: url(/static/media/picImage.1571521b.jpeg)  #CCC no-repeat left top;
    min-height: 100%;
    min-width: 100%;
   /* background-size: cover ;*/
    background-size: 100% 100% ;

    font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;

}

.bigImageDiv{
    margin-top: 0;
}
.bigImageDiv p{
    color: white;
}
.bigImageDiv small{
    color: white;
    font-size: 11px;

}
.rearRealtimeDiv{
    background-color:  #13B5EA;
    min-height: 60px;
    margin-top: 0px;
}
.rearRealtimeDiv p{
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
}
.rearRealtimeDiv div.small{
    color: white;
    text-align: center;
}
.jumbotron{
    margin-bottom: 0;
}

.intuitiveFeaturesDiv{
    color: black;
    text-align: center;
}
.intuitiveFeaturesDiv p{
    font-weight: bold;
    padding-top: 5px;
    font-size: 18px;
}
.myService{
    padding-left: 5px;
    text-align: center;
    margin: 0 auto 0;
}
.myService p.title{
    padding-left: 5px;
    text-align: center;
    font-weight: bold;
    margin: 0 auto 0;
}

/*

 colour = #27C229

 colour = #13B5EA

 colour = #32465A

 */

.registerHeader{
    width: 100%;
    text-align: left;
    height: 55px;
    vertical-align: middle;
    position: relative;
}
.registerHeader img{

    top: 10px;
    height: 50px;
    left: 10%;
    position: relative;
}


.registerHeader2{
    width: 100%;
    text-align: left;
    height: 55px;
    vertical-align: middle;
    position: relative;
}
.registerHeader2 img{

    top: 10px;
    height: 50px;
    left: 10%;
    position: relative;
}
.registerHeader2 span.titleName{
    color: #455b66;
    font-size: 18px;
    top: 25px;
    height: 75px;
    left: 10%;
    position: relative;
    padding-left: 8px;
}


.registerHeader span.titleName{
    color: #455b66;
    font-size: 18px;
    top: 25px;
    height: 75px;
    left: 10%;
    position: relative;
    padding-left: 8px;
}
.mainContent{
    width: 30%;
}
.cButton{
    width: 100%;
    text-align: center;
}
.myCombo{
    -webkit-appearance: menulist-button;
    height: 35px;
    width: 100%;
}

.myPreload{
    top:25%;
    position: absolute;
}

.scroll-x { overflow-x: auto; }
.scroll-y { overflow-y: auto; }

.myDashboardContent01{
    background: url(/static/media/picImage.1571521b.jpeg)  #CCC no-repeat left top;
    width: 100%;
    height: 100%;
    /* background-size: cover ;*/
    background-size: 100% 100% ;

    font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;

    flex: 0 1 auto;
    display: flex;
    flex-flow: column;
    height: 100%;
    position: relative;
}

div.myDashboardContent01
{
   min-height: calc(100vh - 130px);
    padding: 0 !important;
    height: auto;
    top: -20px;
    right: -15px;
    height: auto;
}

.myDashboardContent02{
    opacity: 0.8;
    background:  #D8D8D8;
    position: relative;
    min-height: calc(100vh - 130px);
    padding-top: 20px;
}
 .bockContent{
    background: #13B5EA;
    color: white;
     width: 100%;
}
.pTitleHeader{
    font-weight: bold;
    font-size: 16px;
    width: 70%;
    position: relative;
    float: left;
    padding-left: 10px;
    background: #13B5EA;
    height: 100px;

    padding-top: 15px;
    vertical-align: middle;
    /* Admin2018! */
}
.pTitleNumber{
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 25px;
    width: 25%;
    position: relative;
    background: #13B5EA;
    height: 75px;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;

}
.myColorB{
    background: #13B5EA;
    border-radius: 15px;
}
div.myColorB{
    border-left: 5px solid #D8D8D8;
}
.circle {
    border-radius: 75px;
    -moz-border-right-colors: white;
    background: #13B5EA;
    border: 5px solid white;

}
.pContentBox{
    background: #4f565d;
}
.pContentBox i{
    padding-top: 10px;
    color: white;
    font-size: 54px;
}
div.makeBorderShort{
    border-left: 1px solid white;
}
div.pBorderConter1{
    height: 65px;
    background: #13B5EA;
    left:5px;
    border-radius: 5px 5px;
}
.blockContentColoum2{
    background: #4f565d;
    color: white;
    margin-left: 5px;
}
div.blockContentColoum2{
    border-bottom: 10px solid white;
}
.blockContentColoum2 p{
    width: 110%;
    let:-10px;
}
.alignMeLeft{
    text-align: left;
    font-weight: bold;
}
.alignMeRight{
    text-align: right;
    font-weight: bold;
}
.bccHeader{
    background: #13B5EA;
    /*background: orangered;*/
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}
.bccGlobal{
    background: #13B5EA;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}
.badgeColorRed{
    color:white;
    background: red;
}
.badgeColorGreen{
    color:white;
    background: green;
}
.badgeColorYellow{
    color:black;
    background: yellow;
}
.colBlock{
    margin-left: 10px;
    width: 25%;
}
.makeBock25Percent{
    width: 25%;
}

.bccChartHearder{
    color: white;
    border-radius: 15px;
    border-left: 20px solid #D8D8D8;
    border-right: 20px solid #D8D8D8;
}
.bccChartContent{
    background: white;
    border-left: 20px solid #D8D8D8;
    border-right: 20px solid #D8D8D8;
    text-align: center;
    padding-top: 50px;
    min-height: 300px;

}
.rowGlobal{
    border-radius: 55px;
}
.glbRed{
    color: red;
    font-weight: bold;
    font-size: 24px;
}
.glbGreen{
    color: green;
    font-weight: bold;
    font-size: 24px;
}
.glbYellow{
    color: yellow;
    font-weight: bold;
    font-size: 24px;
}

.myCombo2{
    -webkit-appearance: menulist-button;
    height: 35px;
    width: 85%;
}
.colorMeBrwon{
    color: orangered;
    font-size: 24px;
}


.modal-dialog {
    text-align: left;
    margin: 0 auto 0;
    left: 25%;
    margin-top: 5%;
    background: lightgrey;
    width: 70%;

    position: absolute;


}

.myModalPosition{
    width: 90%;
    background: inherit;
}
.clickMe{
    cursor: pointer;
    color: blue;
}
.mybuttonOrg{
    text-align: right;
}
 tr.noborder td{
     border-color: orangered;
    border-top: none !important;

}
.page{
    height: 100%;
    display: inline-block;
}
.pageContentWhite{
    width: 100%;
    height: auto;
    background: white;
    position: relative;
    margin-top:0px;
    vertical-align: top;
    top:-10px;
}

.borderShadon{
    border: 2px double;
    border-color: lightgray;
    padding: 10px;
    box-shadow: 5px 10px 18px gray;
    min-height: calc(100vh - 350px);
}
.cOrg12{
    width: 30%;
}
.cOrg13{
    width: 20%;
    text-align: left;
    background: yellow;
}

.logoColoum{
    height: 90px ;
    width: 130px ;
    background-color: #666d74;
    padding-top: 5px;

}
.myLogo{
    background-color: #fcf8e3;
    min-heightheight: 150px ;
    min-width: 150px ;

}
.myImgBackground{
    background-color: white;
}

.select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    display: block;
    width: 100%;
    height: 34px;
}

.loader{
    position: absolute;
    margin: 0 auto 0;
    margin-top: -18%;
    margin-left: 25%;
}

.loader p{
    text-align: center;
    font-size: 18px;
    color: red;
    font-weight: bold;
}

div.siteTableContainer{
    width: 90%;

}

.mySiteTable{
    width: 100%;
    position: relative;
}
.mySiteTable td,th{
    text-align: center;
}
button.date-picker-custom-input{
    width: 100%;
    position: relative;
}

.empPayRates{
    color: red;
    font-size: 14px;
}

.myclocks table{
    overflow-x: scroll;
    background: yellow;

}
.myClassGray td{
    color:  	#DCDCDC;
}

img.imgContractDash{
    width: 99%;
    margin: 0 auto;
}

.centerContent{
    padding: 10px;
}
.ImportEmployeeDatabaseCsvTitle{
    text-align: center;
    font-size: 25px;
}
.totDatabaseQuery{
    bottom: 40px;
    right: 40px;
}
.myBoxListItemDoc{
   padding-left:60px;
}
.hidenMe{
    visibility: hidden;
}

.tAlignLeft{
    text-align: left;
}
.tAlignRight{
    text-align: right;
}
.tAlignCenter{
    text-align: center;
}
table.grayTableHeader th{
    color: #818a91;
    opacity: 0.8;
}
.myFiterHeader{
    font-size: 18px;
    font-weight: bold;
}

table.tbSiteReport{
    background-color: lightgrey;
}
table.tbSiteReport td{
    font-size: 16px;
    font-weight: bold;
    color: black;
}
table.tbSiteReport th{
    color: darkgrey;
}
table.tbSiteReport th.vtable {
    vertical-align: middle;
    padding: 20px;
    font-size: 18px;
    color: black;
}
.myFirstRowCol{
    font-weight: bold;
    font-size: 14px;
}

table.pastelTable th{
    text-align: left;
}

.pastelHeading{
    color: red;
}
.pastelDownloadBtn{
    height: 30px;
    cursor: pointer;
    color: #3f51b5;
}
.pastelTitleDetail{
    color: #4f565d;
}
.tab-header{
    background-color: black;
}




.mainModal{
    width: 90%;
    background: green;
    margin: 0 auto 0;
}


.conctactPopBody{

}

.modal-dialog {
    width: 98%;
    height: 92%;
    padding: 0;
}

.modal-content {
    height: 99%;
}

.fmContract{
    width: 90%;
    margin-top: 25px;
    border: 1px solid #818a91;
    padding-bottom: 10px;
    padding-top: 10px;
    min-height: 300px;
}

.fmContract .scStepTitle{
    color: #00b3ee;
    font-size: 18px;
}
p.alignMeLeft{
    width: 100%;
    margin: 0 auto 0;
}
button.push-bottom{
    margin-bottom: 0px;
    position: relative;
}

.ctForm label{
    font-weight: bold;
    color: gray;
}
.fieldRequired{
    color: red;
    font-weight: bold;
    font-size: 20px;
}

.fmContract .nextBtn{
    margin-right: 15px;
}

.fmContract .pull-left{
    margin-left: 15px;
}

tr.rowNoBorder{
    border: 2px solid white;
}
.myPayRatesBlock h3{
    font-weight: bold;
    color: #818a91;
}

.myPayRatesBlock label{
    color: #818a91;
}
.myPayRatesBlock th{
    color: #818a91;
}
.designationRole{
    color: #818a91;
}
.myPayRatesBlock select{
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
    background-position: 97% center;
    background-repeat: no-repeat;
    border: 1px solid #AAA;
    color: #555;
    font-size: inherit;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
    height: 35px;
    padding-left: 0px;
}

/*select#soflow, select#soflow-color {
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
    background-position: 97% center;
    background-repeat: no-repeat;
    border: 1px solid #AAA;
    color: #555;
    font-size: inherit;
    margin: 20px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
}*/


.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.myPayRatesBlock .select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    box-sizing: border-box;

    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    display: block;
    width: 100%;
    height: 34px;
}

.sitecontent a.clickMe{
    margin-right: 10px;
    font-size: 14px;
    color: #00b3ee;
    text-decoration: underline;
    font-weight: bold;
}
.sitecontent p{
    margin-left: 10px;
}

.sitecontent h3{

    color: #818a91;
    font-size: 16px;
    width: 90%;
    margin: 0 auto 0;

}
div.myButtonNav{
    margin-bottom: 0;
    position: relative;
    bottom: 0px;
}

.sitecontent table{
    width: 90%;
    margin: 0 auto 0;
    margin-bottom: 55px;
}

.sitecontent table th,td{
    color: dimgrey;
}

.sitecontent  .myButtonNav{
    width: 90%;
    margin: 0 auto 0;
}

 p.my90Perc{
    width: 90%;
    margin: 0 auto 0;
    color: #4f565d;
     margin-bottom: 85px;
}

 .btnEditCustom{
    background:black;
     color:white;
 }

 .sitecontent table{
     width: 45%;
     margin-left: 15px;
 }
.sitecontent h3{
    margin-left: 15px;
}
.sitecontent table.dsTable tr{
    border: 5px solid white;
}


/*
.stepwizard-step p {
    margin-top: 10px;
}
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    width: 50%;
    position: relative;
}
.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-order: 0;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}
btn-triangle{

}
*/
.btn-arrow-down {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #00b3ee;
}

.menuTitle{
    color: #00b3ee;
    font-size: 18px;
}
.menuTitle i, .menuTitle span{
    color: #00b3ee;
}

.menuDetail{
    padding-left: 25px;
}

.settingMenu{
    margin-left: 30%;
}

.settingMenu hr{
    width: 100%;
    position: relative;
    right: 20%;
}

div.myline{
    border-bottom: 5px solid dimgrey;
    width: 35%;
    margin-bottom: 10px;
}


button.addButton{
    margin-right: 20px;
    margin-bottom: 10px;
}

.mycompliance{
    width: 100%;
    overflow: scroll;
}


.sigCanvas{
    border-width: 2px;
    border-radius: 25px;
    border-color: gray;
}


.container1 {
    width: 100%;
    height: 100%;
    top: 10%;
    left: 10%;
}

.sigContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto 0;
    background-color: #fff;
}

.sigPad {
    width: 100%;
    height: 100%;
}

.buttons {
    width: 100%;
    height: 30px;
}

.sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
}

/*
================
 */


.signature-pad {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    width: 100%;
    height: 100%;
    max-width: 700px;
    /*max-height: 460px;*/
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
    border-radius: 4px;
    padding: 16px;
}

.signature-pad::before,
.signature-pad::after {
    position: absolute;
    z-index: -1;
    content: "";
    width: 40%;
    height: 10px;
    bottom: 10px;
    background: transparent;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
}

.signature-pad::before {
    left: 20px;
    -webkit-transform: skew(-3deg) rotate(-3deg);
    transform: skew(-3deg) rotate(-3deg);
}

.signature-pad::after {
    right: 20px;
    -webkit-transform: skew(3deg) rotate(3deg);
    transform: skew(3deg) rotate(3deg);
}

.signature-pad--body {
    position: relative;
    flex: 1 1;
    border: 1px solid #f4f4f4;
}

.signature-pad--body
canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.signature-pad--footer {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 8px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

