.myTableEmailValidation{
    width: 60%;
    margin: 0 auto 0;
    min-height: 50%;
    margin-bottom: 10%;
}
.myHeader{
    width: 60%;
    height: 110px;
    text-align: center;
    padding-top: 5px;
    margin: 0 auto 0;
}
.myFooter{
    width: 40%;
    height: 110px;
    text-align: center;
    padding-top: 5px;
    margin: 0 auto 0;
    margin-top: 100px;
}

.myHeader img{
    left: 50%;
}

.myContent1{
    width: 60%;
    height: 110px;
    text-align: center;
    padding-top: 5px;
    margin: 0 auto 0;
}
.myContent1 a{
    top: 20px;
    height: 50px;
    background:  #13B5EA;
    color: white;
    font-size: 16px;
    padding: 5px;
    font-weight: bold;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}