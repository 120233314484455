.myPage{
    width: 100%;
    height: 100%;
}
.myTop{
    background-color: #32465A;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    min-height: 40px;
    vertical-align: middle;
}
.myTop button{
    height: 30px;
}
.btnRegister{
    margin-left: 5px;
}
.bigImageDiv1{
    background: url("../images/landing-page/HomePagePic.jpg");
    min-height: 275px;
    min-width: 100%;
    /*background-size: 100% 275px;*/

}
.bigImageDiv{
    background: url("../images/landing-page/picImage.jpeg")  #CCC no-repeat left top;
    min-height: 100%;
    min-width: 100%;
   /* background-size: cover ;*/
    background-size: 100% 100% ;

    font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;

}

.bigImageDiv{
    margin-top: 0;
}
.bigImageDiv p{
    color: white;
}
.bigImageDiv small{
    color: white;
    font-size: 11px;

}
.rearRealtimeDiv{
    background-color:  #13B5EA;
    min-height: 60px;
    margin-top: 0px;
}
.rearRealtimeDiv p{
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
}
.rearRealtimeDiv div.small{
    color: white;
    text-align: center;
}
.jumbotron{
    margin-bottom: 0;
}

.intuitiveFeaturesDiv{
    color: black;
    text-align: center;
}
.intuitiveFeaturesDiv p{
    font-weight: bold;
    padding-top: 5px;
    font-size: 18px;
}
.myService{
    padding-left: 5px;
    text-align: center;
    margin: 0 auto 0;
}
.myService p.title{
    padding-left: 5px;
    text-align: center;
    font-weight: bold;
    margin: 0 auto 0;
}

/*

 colour = #27C229

 colour = #13B5EA

 colour = #32465A

 */