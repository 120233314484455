
.mainModal{
    width: 90%;
    background: green;
    margin: 0 auto 0;
}


.conctactPopBody{

}

.modal-dialog {
    width: 98%;
    height: 92%;
    padding: 0;
}

.modal-content {
    height: 99%;
}

.fmContract{
    width: 90%;
    margin-top: 25px;
    border: 1px solid #818a91;
    padding-bottom: 10px;
    padding-top: 10px;
    min-height: 300px;
}

.fmContract .scStepTitle{
    color: #00b3ee;
    font-size: 18px;
}
p.alignMeLeft{
    width: 100%;
    margin: 0 auto 0;
}
button.push-bottom{
    margin-bottom: 0px;
    position: relative;
}

.ctForm label{
    font-weight: bold;
    color: gray;
}
.fieldRequired{
    color: red;
    font-weight: bold;
    font-size: 20px;
}

.fmContract .nextBtn{
    margin-right: 15px;
}

.fmContract .pull-left{
    margin-left: 15px;
}

tr.rowNoBorder{
    border: 2px solid white;
}
.myPayRatesBlock h3{
    font-weight: bold;
    color: #818a91;
}

.myPayRatesBlock label{
    color: #818a91;
}
.myPayRatesBlock th{
    color: #818a91;
}
.designationRole{
    color: #818a91;
}
.myPayRatesBlock select{
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
    background-position: 97% center;
    background-repeat: no-repeat;
    border: 1px solid #AAA;
    color: #555;
    font-size: inherit;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90%;
    height: 35px;
    padding-left: 0px;
}

/*select#soflow, select#soflow-color {
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
    background-position: 97% center;
    background-repeat: no-repeat;
    border: 1px solid #AAA;
    color: #555;
    font-size: inherit;
    margin: 20px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
}*/


.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.myPayRatesBlock .select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    display: block;
    width: 100%;
    height: 34px;
}

.sitecontent a.clickMe{
    margin-right: 10px;
    font-size: 14px;
    color: #00b3ee;
    text-decoration: underline;
    font-weight: bold;
}
.sitecontent p{
    margin-left: 10px;
}

.sitecontent h3{

    color: #818a91;
    font-size: 16px;
    width: 90%;
    margin: 0 auto 0;

}
div.myButtonNav{
    margin-bottom: 0;
    position: relative;
    bottom: 0px;
}

.sitecontent table{
    width: 90%;
    margin: 0 auto 0;
    margin-bottom: 55px;
}

.sitecontent table th,td{
    color: dimgrey;
}

.sitecontent  .myButtonNav{
    width: 90%;
    margin: 0 auto 0;
}

 p.my90Perc{
    width: 90%;
    margin: 0 auto 0;
    color: #4f565d;
     margin-bottom: 85px;
}

 .btnEditCustom{
    background:black;
     color:white;
 }

 .sitecontent table{
     width: 45%;
     margin-left: 15px;
 }
.sitecontent h3{
    margin-left: 15px;
}
.sitecontent table.dsTable tr{
    border: 5px solid white;
}

