
.registerHeader{
    width: 100%;
    text-align: left;
    height: 55px;
    vertical-align: middle;
    position: relative;
}
.registerHeader img{

    top: 10px;
    height: 50px;
    left: 10%;
    position: relative;
}


.registerHeader2{
    width: 100%;
    text-align: left;
    height: 55px;
    vertical-align: middle;
    position: relative;
}
.registerHeader2 img{

    top: 10px;
    height: 50px;
    left: 10%;
    position: relative;
}
.registerHeader2 span.titleName{
    color: #455b66;
    font-size: 18px;
    top: 25px;
    height: 75px;
    left: 10%;
    position: relative;
    padding-left: 8px;
}


.registerHeader span.titleName{
    color: #455b66;
    font-size: 18px;
    top: 25px;
    height: 75px;
    left: 10%;
    position: relative;
    padding-left: 8px;
}
.mainContent{
    width: 30%;
}
.cButton{
    width: 100%;
    text-align: center;
}
.myCombo{
    -webkit-appearance: menulist-button;
    height: 35px;
    width: 100%;
}

.myPreload{
    top:25%;
    position: absolute;
}