
.select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    display: block;
    width: 100%;
    height: 34px;
}

div.contentBody{
    height: 70%;
    margin-top: 20%;
    margin-bottom: 5%;
    position: absolute;
    width: 80%;
}
div.myContentAddEmployee{
    padding: 10px;
    height: 90%;
    overflow: scroll;
    margin-bottom: 5%;
}

.myCsvImport{
    margin: 0 auto 0;
    width: 40%;
}