
/*@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css");*/

body{
    background-color: white;
}


.mysetting01  > .active > a, .mysetting01  > .active > a:hover, .mysetting01  > .active > a:focus {
    color: #13B5EA;
    background-color: white;
}

li.active > a{
    color: #13B5EA;
    background-color: red;
}

.nnavmenu{
    bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
}

.myPageTitle{
   /* background-color: #D8D8D8;*/
    background-color: #D8D8D8;
    top: 0;
    position: relative;
    min-height: 25px;
    padding-left:6%;
    padding-bottom: 1px;
    padding-top: 1px;
    width: 100%;
    margin-top: 0px;
    top:-20px;

}

.topMenuLevel1{
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 0px;
    padding-right: 5px;
    background: #32465A;
    color: white;
    min-height: 50px;
    position: relative;
    margin-bottom: 0px;

}
.topMenuLevel1Login{
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 5px;
    background: #13B5EA;
    color: white;
    min-height: 50px;
    text-align: center;
    margin-bottom: 0px;
}

.topMenuLevel2{
    padding-left: 50px;
    padding-top: 8px;
    padding-bottom: 0px;
    padding-right: 5px;
    background: #13B5EA;
    color: white;
}
.popupMenuLoginUser
{

    display:none;
    position: absolute;
    width: 200px;
    min-width: 200px;
    max-width: 300px;
    background: #FFF;
    border: #D7D7D7 1px solid;
    -moz-box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
    -khtml-box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10000;
}
.pmlu_01{
    top: 239px;
    left: 874px;
    display: block;
}

.dropdown-btn {
    padding: 6px 6px 6px 6px;
    text-decoration: none;
    font-size: 14px;
    color: orangered;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

.fa-caret-down {
    float: right;
    padding-right: 8px;
    font-size: 24px;
}
.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}


.dropMenu a, .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

/* On mouse-over */
.dropMenu a:hover, .dropdown-btn:hover {
    color: #f1f1f1;
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
    .dropMenu {padding-top: 15px;}
    .dropMenu a {font-size: 18px;}
}

.decoreTxt{
    color:  white;
    font-size: 14px;
    font-weight: bold;
}

.boldMe{
    font-weight: bold;
}

hr{
    color: orangered;
    border-top: 1px solid #8c8b8b;
}

hr.style18 {
    height: 5px;
    border-style: solid;
    border-color: #818a91;
    border-width: 1px 0 0 0;
    border-radius: 20px;
}
hr.style18:before {
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: #818a91;
    border-width: 0 0 1px 0;
    border-radius: 20px;
}

/*
customize the background color of menu
 */

/* navbar */
.navbar-default {
    background-color:  #13B5EA;
    border-color: #13B5EA;
    margin-top: 2px;
    padding: 0px;
}

/* Link */
.navbar-default .navbar-nav > li > a {
    color: white;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #333;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #13B5EA;
    background-color: white;

}



/* circle draw */

.circleTextFormat{
    vertical-align: middle;
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
}

.circle2 {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 18px;
    color:  #13B5EA;
    line-height: 50px;
    text-align: center;
    background: white;
}
.undecoraMe{
    list-style: none;
    color: white;
}

.dropdown-toggle::after {
    color: white;
}